@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

$background: #f9f9f9;
$main: #b8c6db;
$blue-1: #f0faff;
$mint: #e6f4f1;
$gray-1: #e2e8f0;
$white-1: #fff;
$white-2: #f1f1f1; //new change
$purple: #aca7cb; //new
$dark-1: #474554; //new , change
$green: #43655a; //new
$mute_green: #618180; //new

//Header
* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}

//home
.HomeTd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $white-1;
  min-width: 200px;
  height: 130px;

  > h4 {
    font-weight: 600;
    font-size: 20px;
  }
  > p {
    font-size: 18px;
  }
}

//members
.MemberContainer {
  padding: 36px;

  @media screen and (max-width: 768px) {
    padding-right: 24px;
    padding-left: 24px;
  }
}

.MemberCheck {
  > label {
    margin-right: 5px;

    > span {
      margin: 4px;
    }
  }
}

.DatePickerStyle {
  border: 1px solid #f0faff;
  border-radius: 15px;
  background-color: #f0faff;
  padding: 5px 15px;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.IconDatePicker {
  width: 30px;
  height: 30px;
}

.selectOption {
  > option:first-child {
    color: $white-2;
  }
}

.react-datepicker__triangle {
  display: none;
}

.SearchFlex {
  max-width: 600px;

  > input {
    width: 100%;
    outline: 0;
    background-color: $white-1;
    border: 1px solid $gray-1;
    border-radius: 5px;
    padding: 8px;
  }

  > button {
    margin-left: 10px;
  }

  @media screen and (max-width: 480px) {
    > input {
      width: 100%;
    }

    > button {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.TableContainer {
  padding: 36px;
  // max-width: 1400px;
  margin: 0 auto;
}

//Table Custom - prompt

.CustomTableStyle {
  min-width: 800px;
  width: 100%;
  background-color: #fff;
}

.CheckBox {
  //padding: 0 10px;
  min-width: 60px;

  > label {
    margin-top: 5px;
  }
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Custom-tr {
  height: 48px;
  border: 1px solid #ededed;
  border-left: 0;
  border-right: 0;
}

.Custom-thead-tr {
  background-color: #ededed;
}

.Custom-th1 {
  min-width: 190px;
}

.Custom-th2 {
  min-width: 100px;
}

.Custom-th3 {
  min-width: 150px;
}

.Custom-th4 {
  min-width: 100px;
}

//makePromtpt

.makePromtLabelInput {
  margin-bottom: 23px;

  > label {
    width: 150px;
    font-weight: 600;
    word-break: keep-all;
    margin-bottom: 5px;
  }

  > input {
    width: 100%;
    border: 1px solid $dark-1;
    padding: 5px 8px;
  }
}

.makePromtLabelInputTwo {
  margin-bottom: 23px;

  > label {
    width: 150px;
    font-weight: 600;
    word-break: keep-all;
    margin-bottom: 5px;
  }
  > input {
    width: 100%;
    border: 1px solid $dark-1;
    padding: 5px 8px;
  }
}

.makePromtLabelTextarea {
  margin-bottom: 23px;

  > label {
    width: 150px;
    font-weight: 600;
    word-break: keep-all;
    margin-bottom: 5px;
  }

  > textarea {
    width: 100%;
    border: 1px solid $dark-1;
    border-radius: 0;
    resize: none;

    height: 150px;
    overflow: auto;
    padding: 5px 8px;
  }

  > div > textarea {
    resize: none;
    width: 100%;
    height: 150px;
    overflow: auto;
    padding: 5px 8px;
  }
}

//Custom Table - Members

.MemberCustomTableStyle {
  min-width: 1200px;
  width: 100%;
  background-color: #fff;
}

.MemberCheckBox {
  //padding: 0 10px;
  min-width: 40px;
  max-width: 80px;

  > label {
    margin-top: 5px;
  }
}

.MemberCustom-tr {
  height: 48px;
  border: 1px solid #ededed;
  border-left: 0;
  border-right: 0;
}

.MemberCustom-thead-tr {
  background-color: #ededed;
}

.MemberCustom-th1 {
  min-width: 200px;
}

.MemberCustom-th2 {
  min-width: 200px;
}

.MemberCustom-th3 {
  min-width: 130px;
}

.MemberCustom-th4 {
  min-width: 130px;
}
.MemberCustom-th5 {
  min-width: 100px;
}

.MemberCustom-th6 {
  min-width: 130px;
}
.MemberCustom-th7 {
  min-width: 130px;
}

.MemberCustom-th8 {
  min-width: 100px;
}

.MemberCustom-th9 {
  min-width: 100px;
}

//Custom table - paymentlog

.paymentCustom-th1 {
  min-width: 100px;
}

.paymentCustom-th2 {
  min-width: 160px;
}

.paymentCustom-th3 {
  min-width: 160px;
}

.paymentCustom-th4 {
  min-width: 80px;
}
.paymentCustom-th5 {
  min-width: 100px;
}

.paymentCustom-th6 {
  min-width: 100px;
}

//MemInfo ,MemPay

.InfoPayContainer {
  width: 100%;
  max-width: 1200px;
  padding: 120px 64px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding-right: 36px;
    padding-left: 36px;
  }
}

.InfoContent {
  border: 1px solid $dark-1;
  background-color: $white-1;
}

//Custom table - Question

.QuestionCustomTableStyle {
  min-width: 800px;
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
}

.QuestionCustom-tr {
  height: 48px;
  border: 1px solid #ededed;
  border-left: 0;
  border-right: 0;
}

.QuestionCustom-thead-tr {
  background-color: #ededed;
}

.QuestionCustom-th0 {
  min-width: 130px;
}

.QuestionCustom-th1 {
  min-width: 130px;
}

.QuestionCustom-th2 {
  min-width: 130px;
}

.QuestionCustom-th3 {
  min-width: 100px;
}

.QuestionCustom-th4 {
  min-width: 130px;
}

.QuestionCustom-th5 {
  min-width: 300px;
}

.checkedQuestion {
  > td {
    color: #000;
  }
}

.uncheckedQuestion {
  > td {
    font-weight: 600;
    color: #000;
  }
}

.answeredQuestion {
  > td {
    color: gray;
  }
}
